<template>
  <div class="text:center">
    <table class="table is-bordered is-striped" style="width: 100%">
      <thead>
        <tr>
          <th style="text-align: left">Week</th>
          <th colspan="2" style="
              text-align: center;
              border-left-width: medium;
              border-right-width: medium;
            ">
            Mon
          </th>
          <th colspan="2" style="
              text-align: center;
              border-left-width: medium;
              border-right-width: medium;
            ">
            Tue
          </th>
          <th colspan="2" style="
              text-align: center;
              border-left-width: medium;
              border-right-width: medium;
            ">
            Wed
          </th>
          <th colspan="2" style="
              text-align: center;
              border-left-width: medium;
              border-right-width: medium;
            ">
            Thu
          </th>
          <th colspan="2" style="
              text-align: center;
              border-left-width: medium;
              border-right-width: medium;
            ">
            Fri
          </th>
          <th class="weekend" colspan="2" style="
              text-align: center;
              border-left-width: medium;
              border-right-width: medium;
            ">
            Sat
          </th>
          <th class="weekend" colspan="2" style="
              text-align: center;
              border-left-width: medium;
              border-right-width: medium;
            ">
            Sun
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="x in attendanceList" :key="x[0]">
          <td style="text-align: left">{{ x[0] }}</td>

          <td v-for="(y, i) in x.slice(1)" :key="i" style="text-align: center" :style="i % 2 == 0
            ? 'border-left-width: medium'
            : 'border-right-width: medium'
          " :class="i > 9 ? 'weekend' : ''">
            {{ y ?? "?" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "componentsSchoolstudentAttendancelist",

  props: ["student"],

  computed: {
    attendanceList() {

      let weekStarts = Object.keys(this.student.attendance).sort().reverse();


      return weekStarts.map((weekStart) => {
        const dayKeys = Object.keys(this.student.attendance[weekStart]).sort();

        let x = [
          weekStart,
          ...dayKeys.reduce((p, dayKey) => {
            return [
              ...p,
              this.student.attendance[weekStart][dayKey].am,
              this.student.attendance[weekStart][dayKey].pm,
            ];
          }, []),
        ];

        return x;
      });
    },
  },
};
</script>

<style scoped>
tbody td.weekend,
thead th.weekend {
  background: rgba(255, 255, 136, 0.5);
}
</style>
