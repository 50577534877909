import { make } from 'vuex-pathify'


const state = {
  consentsList: undefined,
  currentDate: undefined,
  orders: undefined
}



export default {

  namespaced: true,


  state,
  actions: make.actions(state),
  mutations: make.mutations(state),
  getters: make.getters(state),







}





