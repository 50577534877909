export default [
  {
    path: "/my/school/",

    component: () => import("Views/School.vue"),
    meta: {
      requiresStudent: true,
    },

    children: [
      {
        path: "/my/school/teachers",
        name: "viewsMySchoolTeachers",

        component: () => import("Views/My/School/Teachers.vue"),
        meta: {
          requiresStudent: true,
        },
      },

      {
        name: "mySchoolExams",
        path: "/my/school/exams/",

        component: () => import("Views/School/Timetable/Exams.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
        },
      },
    ],
  },
];
