import format from "date-fns/format";

import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import parseISO from "date-fns/parseISO";

import { get as localKVget, get2 as localKVget2 } from "Helpers/localKV";

export default class Staff {
  constructor() { }

  getPhoto() {
    return (
      this.photo ||
      "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBg+A8AAQQBAHAgZQsAAAAASUVORK5CYII="
    );
  }

  gender() {
    return this.basic?.Gender;
  }

  dob(formatted = false) {
    if (!this.basic) {
      return null;
    }
    if (formatted) {
      return format(parseISO(this.basic.DOB), "do LLL yyyy");
    }
    return this.basic.DOB;
  }

  age() {
    if (!this.basic) {
      return null;
    }
    let age = intervalToDuration({
      start: new Date(this.basic.DOB),
      end: new Date(),
    });
    return formatDuration(age, { format: ["years", "months"], delimiter: "," });
  }

  house() {
    return this.basic?.House;
  }

  getAttributes() {
    return Object.keys(this);
  }

  getID() {
    return this.staffID;
  }

  static async list(referenceData = null) {
    let response = await localKVget2("AllStaff", null, null, [
      "staff/list/",
      true,
    ]);
    if (!response) {
      return [];
    }

    if (referenceData === null) {
      return response.value
    }
    console.log(response);
    return Object.entries(response.value).reduce((p, c) => {
      c[1].id = c[0];

      c[1].StaffCategories = c[1].StaffCategoryIds.map((x) => {
        return referenceData["StaffCategory"][x.toString()]?.description ?? "?";
      }).filter((x) => !x.startsWith("Census -"));

      c[1].StaffWorksInPType = c[1].StaffWorksInPTypeIds.map((x) => {
        return referenceData["PType"][x]?.description ?? "?";
      }).filter((x) => !x.startsWith("Census -"));

      c[1].Department = c[1].DepartmentIds.map((x) => {
        return referenceData["StaffDepartments"][x]?.description ?? "?";
      }).filter((x) => !x.startsWith("Census -"));

      p.push(c[1]);
      return p;
    }, []);
  }

  static async get(staffID) {
    alert("Not implmented")
    return;
    /* return await localKVget("staff", staffID, new Staff(), [
       "staff/id/" + staffID,
       true,
       true,
     ]);*/
  }
}
