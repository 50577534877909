
import { localisedYMD } from "Helpers/functions.js";


export default [{

  path: "/lunch/",
  redirect: "/lunch/" + localisedYMD() + "/view/",
}, {



  path: "/lunch/:date",
  name: "lunchTLManager",
  component: () => import("Views/Lunch/Manage.vue"),
  redirect: "/lunch/" + localisedYMD() + "/view/",
  meta: {
    requiresAuth: true,
    requiresDomain: true,
    requiresDepartment: [47], // TODO - store this in the school DO
  },

  children: [
    {
      path: "view/",
      name: "schoolLunchViewX",
      component: () => import("Views/Lunch/View.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresDepartment: [47],
      },
    }, {
      path: "rawEdit/",
      name: "schoolLunchRawEdit",
      component: () => import("Views/Lunch/RawEdit.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresDepartment: [47],
      },
    },

    {
      path: "edit/:id/",
      name: "schoolLunchEdit",
      component: () => import("Views/Lunch/Edit.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresDepartment: [47], // TODO - store this in the school DO
      },
    },
  ],
}];
