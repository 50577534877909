import { make } from 'vuex-pathify'


const state = {
  current: { options: [] },
  currentDate: undefined,
  orders: undefined
}



export default {

  namespaced: true,


  state,
  actions: make.actions(state),
  mutations: make.mutations(state),
  getters: {
    ...make.getters(state),

    pOrders(state) {
      console.log("Calculating pOrders")
      let cnt = state.orders?.length ?? 0;
      let rtn = (state.orders ?? []).reduce((a, c) => {
        a.options[c.item] = 1 + (a.options[c.item] ?? 0);
        return a;
      }, { count: cnt, options: {} })
      return rtn;
    },
  }







}





