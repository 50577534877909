<template>
  <div class="text:center">
    <table class="table" style="width: 100%">
      <thead>
        <tr>
          <th>Teacher</th>
          <th style="text-align: right">Subjects</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="teacher in teachers" :key="teacher.staffID">
          <td style="text-align: left">
            {{ teacher.teacher }}
          </td>
          <td style="text-align: right">
            {{ teacher.subjects }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "componentsSchoolstudentteacherlist",

  props: ["student"],

  computed: {
    teachers: function () {
      let t = this.student?.teachers ?? [];

      return t.map((teacher) => {
        return {
          staffID: teacher.staffID,
          teacher: [teacher.title, teacher.firstName, teacher.lastName].join(
            " "
          ),
          subjects: teacher.subjects.join(", "),
        };
      });
    },
  },
};
</script>
