/* eslint-disable no-console */

import { register } from "register-service-worker";
import { getMessaging, getToken } from "firebase/messaging";
import firebaseConfig from "./firebaseConfig";
import store from "Plugins/store"

import firebase from "firebase/compat/app";

firebase.initializeApp(firebaseConfig);

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      "App is being served from cache by a service worker.\n" +
      "For more details, visit https://goo.gl/AFskqB"
    );
  },
  registered() {
    console.log("Service worker has been registered.");
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    console.log("New content is available; please refresh.");

    window.location.reload(true)
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
})




import { Workbox } from "workbox-window";

const broadcast = new BroadcastChannel('[ss]sw-channel');
broadcast.postMessage({ msg: "Hello" })

let wb;
if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);
  wb.addEventListener("controlling", () => {
    window.location.reload();
  });
  wb.register({ immediate: true }).then((sw) => {


    if ('Notification' in window && 'serviceWorker' in navigator && Notification.permission === "granted") {

      const messaging = getMessaging();

      // Add the public key generated from the console here.
      getToken(messaging, { vapidKey: "BKxMAmGduLHsnQtvYAIEa_so1roslm2LkBuBrMcr-NZuvJXp_XvsVck6y5bspKOmzJRwbfULzLS11Rs0trmZnIU", serviceWorkerRegistration: sw }).then((currentToken) => {
        if (currentToken) {
          store.dispatch("setFCMToken", currentToken)
          broadcast.postMessage({ currentToken })





        }
      })
    }
  })
} else {
  wb = null;
}
export default wb;
