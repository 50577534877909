export default {
  path: "/school/nextsteps",

  component: () => import("Views/School/NextSteps/Home.vue"),
  meta: {
    requiresStaff: true,
  },
  children: [
    {
      path: "",
      name: "schoolNextSteps",
      component: () =>
        import("Views/School/NextSteps/List.vue"),
      meta: {

        requiresStaff: true,
      },
    },

    {
      path: "manage/:id/",
      name: "schoolNextStepsManage",
      component: () =>
        import("Views/School/NextSteps/Manage.vue"),
      meta: {

        requiresStaff: true,
      },
    },



  ]

}
