export const memoPromise = (asyncFn, expiresIn) => {
    const memo = {}
    const statusMap = {}
    const resolves = []
    const rejects = []

    return async function memoizedFn(...args) {
        console.log("memoizedFn: ", args);
        if (args.length === 0) {
            throw new Error(
                "Must provide at least one serializable argument to generate an unique cache key"
            )
        }

        const memoKey = args.toString()

        if (memo[memoKey]) {
            return Promise.resolve(memo[memoKey]())
        }

        if (statusMap[memoKey] === "pending") {
            return new Promise((_res, _rej) => {
                resolves.push(_res)
                rejects.push(_rej)
            })
        }

        try {
            statusMap[memoKey] = "pending"
            const result = await asyncFn(...args)
            statusMap[memoKey] = "success"
            memo[memoKey] = function get() {
                if (typeof expiresIn === "number" && expiresIn > 0) {
                    setTimeout(() => {
                        memo[memoKey] = null
                        statusMap[memoKey] = null
                    }, expiresIn)
                }

                return result
            }
            resolves.forEach(res => res(result))
        } catch (err) {
            statusMap[memoKey] = "error"
            rejects.forEach(rej => rej(err))
            throw err
        }

        return memo[memoKey]()
    }
}