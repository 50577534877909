import Vue from "vue";

const chrome = window.chrome ?? null;

import "core-js"; // only stable feature also is possible with only `core-js`
import "regenerator-runtime/runtime";
import firebaseConfig from "./firebaseConfig.js"

import VueCookies from "vue-cookies";

import App from "./App.vue";
import Vuex from "vuex";
import router from "Plugins/router";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import store from "Plugins/store";
import SiteConstants from "Plugins/global";
import "Helpers/icons";
import VTooltip from "v-tooltip";
import MediumEditor from 'vuejs-medium-editor'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

const broadcast = new BroadcastChannel('[ss]sw-channel');

//store.set("serviceWorkerBroadcast", broadcast)

broadcast.onmessage = (event) => {
  //alert(JSON.stringify(event.data))
  console.log(event.data);
};

import wb from "./registerServiceWorker";


import VueFormJsonSchema from "vue-form-json-schema";
import {
  MazBtn,
  MazInput,
  MazPicker,
  MazSelect,
  MazSlider,
  MazPhoneNumberInput,
  MazCheckbox,
  MazBottomSheet,
  MazLoader,
  MazList,
  MazListItem,
  MazSwitch,
} from "maz-ui";

import api from "Helpers/apicalls.js";

import VueClipboard from "vue-clipboard2";
import { toast } from "bulma-toast";

Vue.prototype.$workbox = wb;
Vue.prototype.$toast = toast;



import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
// for the code highlighting
import 'highlight.js/styles/github.css'

import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";

import { TimeAgo } from "vue2-timeago";


import Loading from "vue-loading-overlay";


import "vue2-timeago/dist/vue2-timeago.css";

//import  VoerroTagsInput from  '@voerro/vue-tagsinput';

import VuePhoneNumberInput from "vue-phone-number-input";

//console.log([process.env.VUE_APP_API_BASE]   );

Vue.config.productionTip = false;

Vue.filter("pluralize", (amount, singular, plural = `${singular}s`) =>
  amount.toString() + " " + (amount === 1 ? singular : plural)
);

Vue.filter('truncate', function (value, size) {
  if (!value) return '';
  value = value.toString().trim();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});



Vue.use(VueFilterDateFormat);

Vue.use(MazBottomSheet);
Vue.use(MazBtn);
Vue.use(MazCheckbox);
Vue.use(MazInput);
Vue.use(MazLoader);
Vue.use(MazPhoneNumberInput);
Vue.use(MazPicker);
Vue.use(MazSelect);
Vue.use(MazList);
Vue.use(MazListItem);
Vue.use(MazSwitch);
Vue.use(MazSlider);

Vue.use(VueCookies, { expires: "7d" });
Vue.use(Vuex);
Vue.use(VTooltip);
Vue.use(SiteConstants);
Vue.use(VueClipboard);
Vue.use(VueEasytable);
Vue.component("vue-loading", Loading);

Vue.component("time-ago", TimeAgo);

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

Vue.component("vue-form-json-schema", VueFormJsonSchema);

Vue.component('medium-editor', MediumEditor)
//Vue.component('tags-input', VoerroTagsInput);

let app = "";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);





import "bulma-switch/dist/css/bulma-switch.min.css";
import "bulma/css/bulma.css";
import "bulma-list/css/bulma-list.css";
import "@voerro/vue-tagsinput/dist/style.css";
import "vue-loading-overlay/dist/vue-loading.css";

import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "maz-ui/lib/css/index.css";

import "./master.css.js";
//import './registerServiceWorker'

Vue.prototype.$api = api;




firebase.auth().onAuthStateChanged((x) => {
  if (x) {
    console.log(x)
    const user = {};
    Object.assign(user, x._delegate);

    store.dispatch("currentUser", user);



    // eslint-disable-next-line no-unused-vars
    /*
    if (chrome && chrome.runtime) {

      chrome.runtime.sendMessage(
        "kjboccbdkbkpekgmkokenecbgghlbnlk",
        { token: user },
        function (response) {
          if (!response.success) console.log(response);
        }
      );
    } */
  } else {
    store.dispatch("currentUser", null);
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      VTooltip,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
