<template>
  <div class="text:center">
    <table class="table" style="width: 100%">
      <tbody>
        <tr>
          <td style="text-align: left">Parent/Guardian</td>
          <td style="text-align: right">
            {{ student.parentSalutation() }}
          </td>
        </tr>

        <tr v-for="x in student.parentPhoneNumbers()" :key="x.id">
          <td style="text-align: left">{{ x.location }}</td>
          <td style="text-align: right">
            <a :href="'tel:+44' + x.number">{{ x.number }}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "componentsSchoolstudentcontactlist",

  props: ["student"],

  computed: {},
};
</script>
