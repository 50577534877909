export default {
  path: "timetable/",
  component: () => import("Views/School/Timetable.vue"),
  meta: {
    requiresAuth: true,
    requiresDomain: true,
  },
  redirect: "/school/timetable/mine/",

  children: [
    {
      name: "schoolTimetableMine",
      path: "mine/",

      component: () => import("Views/School/Timetable/Mine.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      },
    },
    {
      name: "schoolTimetableExamsMineB",
      path: "exams/",

      component: () => import("Views/School/Timetable/Exams.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      },
    },

    {
      path: "group/:gid+",
      name: "schoolTimetableGroupBase",
      component: () => import("Views/School/Timetable/Group.vue"),

      redirect: { name: "schoolTimetableGroupBase.schoolTimetableGroup" },
      children: [
        {
          path: "cards",
          name: "schoolTimetableGroupBase.schoolTimetableGroup",
          component: () => import("Views/School/Timetable/Group/Group.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
            requiresStaff: true,
          },
          params: true,
        },

        {
          name: "schoolTimetableGroupAttributes",
          path: "attr/",

          props: true,
          component: () =>
            import("Views/School/Timetable/Group/GroupAttributes.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          },
        },
        {
          name: "schoolTimetableGroupDayBooks",
          path: "daybooks/",

          props: true,
          component: () =>
            import("Views/School/Timetable/Group/GroupDayBookList.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          },
        },
        {
          name: "schoolTimetableGroupRegisterAndLunch",
          path: "lunch/",

          props: true,
          component: () =>
            import("Views/School/Timetable/Group/GroupRegisterAndLunch.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          },
        },
      ],
    },

    {
      path: "staff/",
      name: "schoolTimetableAllStaff",
      component: () => import("Views/School/Timetable/AllStaff.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      },
    },
    {
      path: "cover/:date+",
      name: "schoolTimetableCover",
      component: () => import("Views/School/Timetable/Cover.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      },
    },
    {
      path: "room/:date+",
      name: "schoolTimetableFreeRoom",
      component: () => import("Views/School/Timetable/Room.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      },
    },
    {
      path: "class/",
      name: "schoolTimetableForClass",
      component: () => import("Views/School/Timetable/Class.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      },

      children: [
        {
          path: ":gid+/",
          name: "schoolTimetableForClassView",
          component: () => import("Views/School/Timetable/ClassView.vue"),

        }
      ]
    },
  ],
};
