
import format from "date-fns/format";
import { default as api } from "Helpers/apicalls";
import Student from "Classes/student";
import { localisedYMD } from "../helpers/functions";
const ls = require("local-storage");

export default {

  async setLunchOption(state, data) {
    const [selectedOption, selectedOptions] = data

    state.currentUserDataNew.domains[this.state.currentDomain].lunchOrder = [selectedOption, selectedOptions]
  },


  setCurrentUserObj(state, user) {

    state.currentUserObj = user;

  },

  afterInterstitial(state, nextPath) {
    state.afterInterstitial = nextPath;
  },

  referenceData(state, data) {
    state.referenceData[data.type] = data.data;
  },

  userDataNew(state, userData) {
    if (userData) {
      const x = Object.keys(userData).filter((key) => !key.startsWith("_"));

      userData = x.reduce((cur, key) => {
        return Object.assign(cur, { [key]: userData[key] });
      }, {});
    }
    state.currentUserDataNew = {
      domains: userData,
      nextCheck: new Date(new Date().getTime() + 30 * 60000).toISOString(),
      getting: false,
      retrieved: true,
      retrievedAt: format(state.currentDateObj, "yyyy-MM-dd HH:mm:ss")
    };

    ls("ssUserNew", userData);
  },



  resetAuthenticatedData(state) {
    state.currentDomain = null;
    state.currentTimeTable = null;
    state.currentGroup = null;
    state.currentRegGroup = null;
    state.currentProperDate = null;
    state.lastSeenMessages = 0;
    state.lastReceivedMessage = 0;
    state.isStaff = false;
    state.isStudent = false;
    state.isAdult = false;
    state.isGuest = false;
    state.profile = undefined;
    state.referenceData = {};
    state.newAuthDetails = {}
    state.newJWT = null
    state.wsID = null
    if (state.ws) {
      state.ws.close()
      state.ws = null
    }

    state.messages = [];
    state.currentUserObj = null;
    state.currentUserDataNew = {
      nextCheck: "0",
      getting: false,
      retrieved: false,
      domains: {},
    };
    state.currentGroupStudents = {};
    state.formsList = [];
    state.streams = [];


    ls("lastSeen", 0);
    ls("ssUserNew", null);
  },



  addMessage(state, payload) {
    state.messages.push(payload);








    if (payload.timestamp ?? 0 > state.lastReceivedMessage) {
      state.lastReceivedMessage = payload.timestamp;
    }

    if (payload._historical) {
      return;
    }
    if ('serviceWorker' in navigator && Notification.permission === "granted") {
      navigator.serviceWorker.ready // returns a Promise, the active SW registration
        .then(swreg => swreg.showNotification("From: " + payload.data.name, {
          body: payload.data.message,
          icon: '/img/icons/' + (payload.data.icon ?? (payload.data.override ? "icon-park-solid--alarm.png" : "ic--baseline-message.png")),
          image: '/img/autumn-forest.png',
          vibrate: [300, 200, 300],
          badge: '/img/icons/plint-badge-96x96.png',
          // actions: [
          //     { action: 'confirm', title: 'Okay', icon: '/img/icons/android-chrome-192x192.png'},
          //     { action: 'cancel', title: 'Cancel', icon: '/img/icons/android-chrome-192x192.png'}
          // ],
        }))
    }

  },





  setFormsList(state, forms) {
    state.formsList = forms;
  },



  setCurrentTimeTable(state, timetable) {
    state.currentTimeTable = timetable;
  },






  setReferenceData(state, ref) {
    state.referenceData[ref.type] = ref.data;
  },

  clearCurrentDomain(state) {
    state.isStaff = false;
    state.isStudent = false;
    state.isAdult = false;
    state.isGuest = false;
    state.profile = undefined;
    state.referenceData = {};
    state.newAuthDetails = {}
    state.newJWT = null
    state.wsID = null
    if (state.ws) {
      state.ws.close()
      state.ws = null
    }
    state.currentDomain = null;
  },

  setCurrentGroup(state, group) {

    console.log("Setting group to: ", group)

    state.currentGroup = group;
    if (group === null) {
      state.currentRegGroup = null;
      state.currentGroupStudents = {};
    } else {
      console.log("I am setting the current group...", group)


      state.currentGroupStudents = group.group.studentsDetails

    }
  },

  setCurrentGroupStudent(state, student) {
    console.log({ currentGroup: state.currentGroup })
    if (!state.currentGroup?.group?.students.includes(student._id.toString())) {
      console.log("I have been asked to add ", student._id, "to group, but they are not in the group definition")
      return;
    }
    if (typeof student.name !== "function") {
      student = Object.assign(new Student(), student);
    }
    if (!state.currentGroupStudents) {
      state.currentGroupStudents = {};
    }
    state.currentGroupStudents[student._id.toString()] = student
    state.currentGroupUpdate = -1 * state.currentGroupUpdate;

  },

  setCurrentRegGroup(state, group) {
    state.currentRegGroup = group;
  },

  updateCurrentRegGroup(state, students) {
    let ok = false;
    // TODO - NEED TO FIX!
    let y = {};
    let instance = this;
    Object.keys(students["data"]).forEach((element) => {
      if (students["data"][element].GroupID != instance.gid) {
        ok = false;
        return;
      }

      y[students["data"][element]["PupilID"]] = students["data"][element];
    });

    if (ok) {
      state.currentRegGroup.students = y;
    }
  },

  hideNavigationBar(state) {
    state.showNavigation = false;
  },
  showNavigationBar(state) {
    console.log("showing nav being called....")
    if (!state.showNavigation) state.showNavigation = true;
  },
  interaction(state) {
    state.lastInteraction = Date.now();
  },
  portalStart(state) {
    state.inPortal = true;
    state.lastInteraction = Date.now();
    state.showNavigation = false;
  },
  updateTimeMut(state) {
    state.currentDateObj = new Date();

    var h = state.currentDateObj.getHours(); // 0 - 23

    if (h > 11) {
      if (h > 16) {
        state.timeOfDay = "Evening";
      } else {
        state.timeOfDay = "Afternoon";
      }
    } else {
      state.timeOfDay = "Morning";
    }

    state.currentDate = format(state.currentDateObj, "cccc, do LLL yyyy");
    state.currentTime = format(state.currentDateObj, "pp");
    state.currentHM = format(state.currentDateObj, "HH:mm");
    state.currentProperDate = format(state.currentDateObj, "yyyy-MM-dd")
    state.currentISODate = state.currentDateObj
      .toISOString()


    // TODO - need to back off if failure

    if ((state.currentUserDataNew.nextCheck ?? "0") <
      state.currentDateObj.toISOString()
    ) {
      console.log("update caused by signInSuccessWithAuthResult");
      this.commit("forceUpdateUserDetails");
      return
    }

    if (!state.currentDomain) {
      return;
    }

    const x = state.currentUserDataNew?.domains?.[state.currentDomain]

    if (!x) {
      return
    }

    if (localisedYMD() > (x?.date ?? 0)) {
      console.log("update caused by change in date");
      this.commit("forceUpdateUserDetails");
      return
    }


  },

  acknowledgeFlag: async function (state, params) {
    let { student, type, key, timestamp } = params;

    console.log({ student, type, key, timestamp });

    if (!state.currentGroup.group.studentDetails[student].acked) {
      state.currentGroup.group.studentDetails[student].acked = {};
    }

    if (!state.currentGroup.group.studentDetails[student].acked[type]) {
      state.currentGroup.group.studentDetails[student].acked[type] = {};
    }

    state.currentGroup.group.studentDetails[student].acked[type][key] =
      timestamp;
    state.currentGroupUpdate = -1 * state.currentGroupUpdate;
  },

  acknowledgeDayBooks: async function (state) {
    (state.currentGroup?.group?.students ?? []).forEach((v, i) => {
      state.currentGroup.group.students[i].unseenDaybooks = false;

      Object.keys(state.currentGroup.group.students[i].daybook ?? {}).forEach(
        (y) => {
          state.currentGroup.group.students[i].daybook[y].forEach((z, j) => {
            state.currentGroup.group.students[i].daybook[y][j].seen = true;
          });
        }
      );
    });
    state.currentGroupUpdate = -1 * state.currentGroupUpdate;
  },

  forceUpdateUserDetails(state) {
    if (state.currentUserDataNew.getting === false) {
      console.log("Forcing update of user as it too old")
      state.currentUserDataNew.getting = true;
      api
        .get("authenticated/me/", true, false)
        .then((data) => this.commit("userDataNew", data));
    }
  },
}
