export default {
  path: "/helpdesk",
  name: "helpdesk",
  component: () => import("Views/Helpdesk/Manage.vue"),
  meta: {
    requiresStaff: true,
    requiresAccessLevel: 25,
  },
  children: [
    {
      name: "helpdeskManageCreate",
      path: "create/",
      component: () => import("Views/Helpdesk/Create.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresAccessLevel: 25,
      },
    },
    {
      name: "helpdeskSetup",
      path: "setup/",
      component: () => import("Views/Helpdesk/Setup.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresAccessLevel: 25,
      },
    },
    {
      name: "helpdeskManageFormSubmission",
      path: "id/:formID/submission/:subDate/:submissionID",
      component: () => import("Views/Helpdesk/Review.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresAccessLevel: 25,
      },
    },
    {
      name: "helpdeskManageFormSubmissionPrint",
      path: "id/:formID/submission/:subDate/:submissionID/:action",
      component: () => import("Views/Helpdesk/Review.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        hideNavigationBar: true,
        requiresAccessLevel: 25,
      },
    },
    {
      name: "helpdeskManageFormEdit",
      path: "id/:formID/edit/",
      component: () => import("Views/Helpdesk/Edit.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresAccessLevel: 25,
      },
    },
  ],
};
