export default {
  path: "/school/consents",

  component: () => import("Views/School/Consents/Home.vue"),
  meta: {
    requiresStaff: true,
  },
  children: [
    {
      path: "",
      name: "schoolConsents",
      component: () =>
        import("Views/School/Consents/List.vue"),
      meta: {

        requiresStaff: true,
      },
    },

    {
      path: "manage/:id/",
      name: "schoolConsentsManage",
      component: () =>
        import("Views/School/Consents/Manage.vue"),
      meta: {

        requiresStaff: true,
      },
    },

    {
      path: "add/",
      name: "schoolConsentsAdd",
      component: () =>
        import("Views/School/Consents/Add.vue"),
      meta: {

        requiresStaff: true,
      },
    },





  ]

}
