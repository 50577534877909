import RouterMySchool from "@/plugins/routers/my/school/";

export default [
  {
    path: "/my/",

    component: () => import("Views/School.vue"),
    meta: {
      requiresAuth: true,
      requiresDomain: true,
    },

    children: [
      ...RouterMySchool,
    ]
  }

]
