import { make } from 'vuex-pathify'


import { objectsTheSame } from "Helpers/functions.js"



const state = {
  formsList: [],
  currentEditForm: undefined,
  originalEditForm: undefined,
}


const mutations = {
  ...make.mutations(state),
  resetFormData(state) {
    state.formsList = []
    state.currentEditForm = undefined;
    state.originalEditForm = undefined
  },
  addFormAfterCreate(state, form) {

    state.formsList.push(form)

  },
  editForm(state, form = undefined) {

    console.log("Hello - edit Form.  Should only be called once :-)",)

    if (form) {
      console.log("I am being set - whoot!", form)

      state.currentEditForm = structuredClone(form);
      state.originalEditForm = structuredClone(form);


    }
    else {
      if (state.currentEditForm) {
        state.currentEditForm = undefined
        state.originalEditForm = undefined
      }
    }

  },

}







console.log({ mutations })
const actions = {
  ...make.actions(state),


}


const getters = make.getters(state)



export default {

  namespaced: true,


  state,
  actions,
  mutations,


  getters: {

    ...getters,

    elements: (state) => {

      console.log(state.forms)
      return state.forms?.currentEditForm?.form?.formFieldGroups;
    },



    /*  getCurrentEditForm(state) {

        return state.currentEditForm;
      },*/

    changesToCurrentForm: (state) => {


      const start = Date.now();
      const c = objectsTheSame(state.currentEditForm, state.originalEditForm, 8)
      const end = Date.now();
      console.log(`Execution time: ${end - start} ms`);

      console.log("The same? ", c)

      return !c


    },




  },








};
