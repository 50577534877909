import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import store from "Plugins/store";
import { saveStudent } from 'Classes/student'
import { saveGroup } from 'Classes/group'

import GitCommit from "../_git_commit";

const ls = require("local-storage");

import { memoPromise } from "Helpers/memopromise.js";


const memoizedFetch = memoPromise(fetch, 5000)


export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

const state = {
  currentWebSocket: null,
  rejoining: false,
  lastConnectionAttempt: 0,
  join: false,
  connected: false,

}

const api = {

  baseUrl: process.env.VUE_APP_API_BASE,

  async switchBase(mode = "production") {
    console.log("Switching base:", mode, process.env.VUE_APP_STAGE)
    if (mode == "dev") {
      this.baseUrl = process.env.VUE_APP_API_BASE_DEV
      state.join = (process.env.VUE_APP_STAGE != "production")

    }
    else {
      this.baseUrl = process.env.VUE_APP_API_BASE
      state.join = (process.env.VUE_APP_STAGE == "production")
    }

    console.log({ state })
  },


  async post(url, accessToken, domain = false, data = {}, headers = {}) {
    let localHeaders = {
      ...headers,
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };

    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false);
      if (!accessToken) {
        return {};
      }
      localHeaders["Authorization"] = `Bearer ${accessToken}`;
    }

    if (domain) {
      localHeaders["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })
      let ablyClientID = store.state.realtime?.auth?.clientId ?? undefined

      if (ablyClientID) {
        localHeaders["X-Ably-Client-ID"] = ablyClientID
      }

      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    console.log(localHeaders);

    // Default options are marked with *
    const response = await fetch(this.baseUrl + url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: localHeaders,
      redirect: "follow", // manual, *follow, error
      //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  },

  async get(url, accessToken, domain = false, headers = null, memod = false) {
    console.log(["GET", { url, accessToken, domain, headers, memod }]);

    if (!headers) {
      headers = {}
    }

    headers = {
      ...headers,
      "Content-Type": "application/json",
      "X-API-Version": 2,
    }

    console.log("Headers now:", headers)


    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false);
      if (!accessToken) {
        return {};
      }
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })
      let ablyClientID = store.state.realtime?.auth?.clientId ?? undefined

      if (ablyClientID) {
        headers["X-Ably-Client-ID"] = ablyClientID
      }

      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    const fetcher = (memod ? memoizedFetch : fetch)

    console.log(memod ? "Using memo" : "Not using memo", url)

    try {
      // Default options are marked with *
      const response = await fetcher(this.baseUrl + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      return response.clone().json(); // parses JSON response into native JavaScript objects
    } catch (e) {
      return undefined;
    }
  },


  async getNewAccessToken(force = false) {


    let x

    if (!force) {
      x = await ls("newToken:" + GitCommit.version)

      if ((x?.decoded?.exp ?? 0) > Math.floor(Date.now() / 1000)) {
        console.log({ CCCCCCCCCCCCCC: x.decoded })
        store.dispatch("setWsID", x.decoded.permissions.wsId)
        connectToWS()
        return x
      }

      x = null
    }

    ls.remove("newToken:" + GitCommit.version)

    if (!x) {
      store.dispatch("setLoadingFull", "auth/swap")



      x = await api.get("auth/swap", true, true, {
        'X-Fcmtoken': store.state.fcmToken
      }, true)

      if (!x.jwt) {
        store.dispatch("clearLoadingFull", "auth/swap")
        return null
      }
    }

    console.log({ newX: x })

    const domain = store.state.currentDomain;

    if (!domain) {
      store.dispatch("clearLoadingFull", "auth/swap")
      return null
    }

    const temp = parseJwt(x.jwt)
    console.log(temp)
    if (!temp.aud && temp.aud != domain) {
      store.dispatch("clearLoadingFull", "auth/swap")
      return null
    }

    x = {
      decoded: temp,
      jwt: x.jwt
    }


    console.log("I am about to store", x)

    ls("newToken:" + GitCommit.version, x)



    store.dispatch("setWsID", temp.permissions.wsId)
    connectToWS()
    store.dispatch("clearLoadingFull", "auth/swap")
    store.dispatch("updateReferenceData");
    return x

  },

  async get2(url, domain = false) {
    console.log(["GET", url, domain, store]);

    let headers = {
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };


    let aT = await api.getNewAccessToken()

    let accessToken = aT?.jwt

    if (!accessToken) {
      console.log("I don't have an access token (new api get2)")
      return false;
    }

    headers["Authorization"] = `Bearer X_${accessToken}`;

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })


      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    try {
      // Default options are marked with *
      const response = await fetch(api.baseUrl + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      return response.json(); // parses JSON response into native JavaScript objects
    } catch (e) {
      return undefined;
    }
  },

  async post2(url, domain = false, data = {}) {
    console.log(["POST", url, domain]);

    let headers = {
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };

    let aT = await this.getNewAccessToken()

    let accessToken = aT?.jwt

    if (!accessToken) {
      return {};
    }

    headers["Authorization"] = `Bearer X_${accessToken}`;

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })


      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    try {
      // Default options are marked with *
      const response = await fetch(this.baseUrl + url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        body: JSON.stringify(data),
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      return response.json(); // parses JSON response into native JavaScript objects
    } catch (e) {
      return undefined;
    }
  },

  async getWithEtag(url, accessToken, domain = false, etag = null) {
    console.log(["GET (etag)", url, accessToken, domain, etag]);

    let headers = {
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };

    if (etag) {
      headers["If-None-Match"] = etag;
    }

    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false);
      if (!accessToken) {
        return {};
      }
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;

      console.log({ realtime: store.state.realtime })
      let ablyClientID = store.state.realtime?.auth?.clientId ?? undefined

      if (ablyClientID) {
        headers["X-Ably-Client-ID"] = ablyClientID
      }

      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    try {
      // Default options are marked with *
      const response = await fetch(this.baseUrl + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (response.status == 304) {
        return {
          status: 304,
        };
      }

      console.log(response);

      response.headers.entries((x) => {
        console.log([x]);
      });

      let etag = response.headers.get("etag");

      console.log("Got an etag of: ", etag);

      return {
        etag: etag,
        status: response.status,
        data: await response.json(), // parses JSON response into native JavaScript objects
      };
    } catch (e) {
      return undefined;
    }
  },

  async getWithEtag2(url, domain = false, etag = null) {
    console.log(["GET (etag)", url, domain, etag]);

    let headers = {
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };

    if (etag) {
      headers["If-None-Match"] = etag;
    }



    let aT = await this.getNewAccessToken()

    let accessToken = aT?.jwt

    if (!accessToken) {
      console.log("I don't have an access token (new api get2)")
      return false;
    }

    headers["Authorization"] = `Bearer X_${accessToken}`;

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })


      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    try {
      // Default options are marked with *
      const response = await fetch(this.baseUrl + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (response.status == 304) {
        return {
          status: 304,
        };
      }

      console.log(response);

      response.headers.entries((x) => {
        console.log([x]);
      });

      let etag = response.headers.get("etag");

      console.log("Got an etag of: ", etag);

      return {
        etag: etag,
        status: response.status,
        data: await response.json(), // parses JSON response into native JavaScript objects
      };
    } catch (e) {
      return undefined;
    }
  },



};

export const getWS = () => {

  if (state.connected) {
    return state.currentWebSocket;
  }
  return null
}

export const forceJoin = () => {
  state.join = true;
}

setInterval(() => {
  if (state.currentWebSocket) {
    console.log("Websocket connected, sending keep alive");
    state.currentWebSocket.send("X-Clacks-Overhead")
  }

}, 30000);

export const connectToWS = async () => {
  console.log({ currentMode: process.env.VUE_APP_STAGE })

  if ((process.env.VUE_APP_STAGE ?? "production") != "production") {
    return;
  }
  state.join = true;
  checkWSConnection()
}


export const checkWSConnection = () => {
  //console.log("In interval check....", { join: state.join, wsID: store.state.wsID })
  if (!state.join || !store.state.wsID) {
    return;
  }
  if (state.currentWebSocket) {
    return
  }
  if (state.rejoining) {
    console.log("Websocket already rejoining.....")
    return;
  }
  if (state.lastConnectionAttempt > Date.now() - 10000) {
    console.log("Websocket connection attempted in last 10 seconds")
    return;
  }

  state.lastConnectionAttempt = Date.now();
  state.rejoining = true;



  // If we are running via wrangler dev, use ws:
  const wss = (api.baseUrl).replace("http", "ws")
  let ws = new WebSocket(wss + "ws/websocket");











  ws.addEventListener("open", _event => {
    state.currentWebSocket = ws;
    state.rejoining = false;


    // Send user info message.
    ws.send(JSON.stringify({ auth: store.state.wsID }));

  });

  ws.addEventListener("message", event => {




    if (event.data == "GNU Terry Pratchett") {
      console.log("Going Postal")
      return;
    }
    let data = JSON.parse(event.data);


    switch (data.type ?? "unknown") {

      case "status":
        state.connected = data.ready ?? false
        store.set("connected", data.ready ?? false)
        break



      case "student":
        console.log("This is a student")
        saveStudent(store.state.currentDomain, data.data)
        break

      case "group":
        console.log("This is a group")
        saveGroup(store.state.currentDomain, data.data)
        break;

      default:

        console.log({ err: "Unknown message type", data })
        break;
    }

  });

  ws.addEventListener("close", event => {
    store.set("connected", false)
    state.currentWebSocket = null;

    state.rejoining = false;
    console.log("WebSocket closed, reconnecting:", event.code, event.reason);

  });


  ws.addEventListener("error", event => {
    store.set("connected", false)
    state.currentWebSocket = null;
    state.rejoining = false;
    console.log("WebSocket error, reconnecting:", event);

  });



}

setInterval(() => checkWSConnection()
  , 1000);





export default api
