export default [
  {
    path: "/forms",

    component: () => import("Views/Forms/Manage.vue"),
    meta: {
      requiresStaff: true,
    },
    children: [
      {
        path: '',
        redirect: 'formsList'
      },
      {
        name: "formsList",
        path: "list/",
        component: () => import("Views/Forms/List.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
        }
      },
      {
        name: "formsManageCreate",
        path: "create/",
        component: () => import("Views/Forms/Create.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
        }
      },

      {
        name: "formsManageFormOverview",
        path: "id/:formID/",
        component: () => import("Views/Forms/Overview.vue"),
        meta: {
          requiresAuth: true,
          requiresDomain: true,
        },
        children: [
          {
            name: "formsManageFormSubmission",
            path: "submission/:subDate/:submissionID",
            component: () => import("Views/Forms/Review.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
            },
          },
          {
            name: "formsManageFormSubmissionPrint",
            path: "submission/:subDate/:submissionID/:action",
            component: () => import("Views/Forms/Review.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
              hideNavigationBar: true,
            },
          },
          {
            name: "formsManageFormEdit",
            path: "edit/",
            component: () => import("Views/Forms/Edit/FieldGroups.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
            },
          },
          {
            name: "formsManageFormEditGroup",
            path: "edit/group/:groupID",
            component: () => import("Views/Forms/Edit/Fields.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
            },
          },
          {
            name: "formsManageFormEditNotificationOnSubmission",
            path: "edit/notifications",
            component: () => import("Views/Forms/Edit/Notifications.vue"),
            meta: {
              requiresAuth: true,
              requiresDomain: true,
            },
          },
        ],
      }

    ],
  },

  {
    path: "/form/:formID",
    name: "form",
    component: () => import("Views/Form.vue"),
    meta: {
      hideNavigationBar: true,
    },
  },
];
